import React from "react";
import "./WebsiteDetails.scss";
import Region from "../Region/Region";
import { useSelector } from "react-redux";
import api from "../../../veracityapi";
import ManageCustomerData from "../../../SharedComponents/ManageCustomerData/ManageCustomersData";
import { useDispatch } from "react-redux";

const WebsiteDetails = (props) => {
	const dispatch = useDispatch();
	const websiteInfo = useSelector(state => state?.websiteDetails?.data);
	const [input, setInput] = React.useState(
		{
			"website_name": websiteInfo?.websiteDetails?.website_name,
			"timezone": websiteInfo?.websiteDetails?.timezone
		}
	);

	const [isChanged, setIsChanged] = React.useState(false);

	const handleInputChange = (e) => {
		e.preventDefault()
		const { name, value } = e.target;
		setInput((preState) => {
			return {
				...preState,
				[name]: value
			}
		})
		setIsChanged(true)
	};

	let [saveButtonStyle, setSaveButtonStyle] = React.useState(false)


	const handleFormSubmit = (e) => {
		e.preventDefault();
		api.PUT(`/website/${websiteInfo.websiteDetails.guid}`, input)
			.then(res => {
				ManageCustomerData({}, dispatch)
				document.getElementById("save-change").innerHTML = "Changes saved";
				setSaveButtonStyle(true)
			}).catch((e) => console.log("Website details error: ", e))
			.then(res => {
				setTimeout(() => {
					setIsChanged(false);
					document.getElementById("save-change").innerHTML = "Save changes";
					setSaveButtonStyle(false)
				}, 5000);
			}
			)
	};

	return (
		<div className="mydetails-content">
			<h3>Website details</h3>
			<form onSubmit={handleFormSubmit}>
				<div className="url-password-input">
					<div className="url">
						<label>Website URL</label>
						<input
							className="inputs"
							type="text"
							name="websiteURL"
							placeholder="Website URL"
							value={websiteInfo?.websiteDetails.primary_hostname}
							onChange={handleInputChange}
							disabled

						/>
					</div>
					<div className="full-name" >
						<label>Website name</label>
						<input

							className="inputs"
							name="website_name"
							placeholder="Website Name"
							value={input.website_name}
							onChange={handleInputChange}
							disabled={props.primaryUser ? false : true}

						/>
					</div>
				</div>
				<Region input={input} handleInputChange={handleInputChange} primaryUser={props.primaryUser} handleFormSubmit={handleFormSubmit} saveButtonStyle={saveButtonStyle} isChanged={isChanged} />
			</form>
		</div>
	)
};
export default WebsiteDetails;