import React from "react";
import "./Table.scss";
import { formatNumber, formatCurrency } from "../../../Utils/utils";
import { Link, useParams } from 'react-router-dom';

const Table = ({ keys, values, total, baseURL, adType }) => {
	const currencyFields = ['spend', 'wasted_spend', 'savings', 'human_click_cost'];
	const percentageFields = ['bot_percentage', 'invalid_clicks_percentage'];
	const currenySymbol = localStorage?.getItem("bcn-currency-symbol");
	const stringFields = ['campaign_name', 'link_notes'];
	const decodeFields = ['campaign_name'];
	const getFormatedValue = (fieldName, value) => {
		
		let retValue = value ?? 0;
		if (typeof value === "number" && currencyFields.includes(fieldName)) {
			retValue = `${currenySymbol}${formatCurrency(value)}`
		} else if (percentageFields.includes(fieldName)) {
			retValue = `${value.toFixed(1)}%`
		} else if (!stringFields.includes(fieldName)) {
			retValue = formatNumber(value);
		}

		if (decodeFields.includes(fieldName)) {
			try {
				retValue = decodeURI(value).replace(/\+/g,"");
			} catch (error) {
				if (error.message.includes("malformed")) {
					console.error("Malformed URI for field:",value);
				}else{
					console.error(error.message)
				}
				retValue = value;
			}
		}
		return retValue;
	};

	const { campaignId, linkId } = useParams();
	let valuesToRemove = ["channel_id", "channel_name", "link_id", "campaign_id", 'leads', "original_url", "wasted_spend", "ad_channels", "engaged", "savings", "bots_classic", "bot_percentage_classic", "wasted_spend_classic", "human_click_cost_classic", "invalid_clicks"];
	if (!!campaignId) {
		valuesToRemove.push('campaign_name');
	}

	if (total['clicks'] === 0) {
		valuesToRemove.push('human_click_cost');
		valuesToRemove.push('human_click_cost_classic');
		valuesToRemove.push('human_percentage');
		valuesToRemove.push('human_percentage_classic');
		valuesToRemove.push('clicks');
		valuesToRemove.push('spend');
		valuesToRemove.push('impressions');
		valuesToRemove.push('invalid_clicks_percentage');
	} else {
		valuesToRemove.push('bots');
		valuesToRemove.push('bot_percentage');
	}


	let filteredKeys = keys.filter((element) => !valuesToRemove.includes(element));


	return (
		<div className="table">
			{filteredKeys.length &&
				<div className="lheader table-row">
					{filteredKeys.map((i, j) =>
						<span key={j} className="cell">{i.replace(/_/gi, ' ').replace(/bots/g, 'Bot Visits').replace(/percentage/g, '%').replace(/visits/g, 'Landed Clicks').replace(/humans/g, 'Human Visits').replace(/^clicks/g, 'Paid Clicks').replace(/Human click cost/ig, 'Cost per human visit')}</span>
					)}
				</div>
			}
			<div className="tbody">
				{values && values.map((row, j) => {
					return (
						<>
							{campaignId === undefined || linkId === undefined ?
								<Link key={j} to={`${baseURL}/${adType}/${row && (row['link_id'] ?? row['campaign_id'])}`} className="table-row" >
									{filteredKeys.map((i, j) => {
										return (<span key={j} className="cell" style={{ color: "#662D91" }}>
											{getFormatedValue(i, row[i])}
										</span>
										)
									}
									)
									}
								</Link> :
								<div className="table-row">{filteredKeys.map((i, j) => {
									return (<span key={j} className="cell">
										{getFormatedValue(i, row[i])}
									</span>
									)
								}
								)
								}
								</div>
							}
						</>
					)
				}

				)}
				<div className="table-row total">
					{filteredKeys.map((i, j) =>
						<span key={j} className="cell">
							{getFormatedValue(i, total[i])}
						</span>
					)
					}
				</div>
			</div>
		</div>
	)
}
export default Table;
