import React from "react";
import { ResponsiveLine } from '@nivo/line';
import { countryToAlpha2, countryToAlpha3 } from "country-to-iso";
import { useLocation } from "react-router";
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import GraphsToolTip from "../../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import moment from "moment";
import CustomTooltip from "../../../../../SharedComponents/CustomTooltip/CustomTooltip";
import { MaxIcon } from "../../../../../SVG/MaxIcon";
import GraphModal from "../../../../../SharedComponents/GraphModal/GraphModal";

const CountriesLineGraph = (props) => {
	const [isModalOpen, setIsModalOpen] = React.useState(false);
	const [maxIcon, setMaxIcon] = React.useState(false);
	const handleMaximizeClick = () => {
		setIsModalOpen(!isModalOpen);
	};
	const { pathname } = useLocation();
	const graphRef = React.useRef(null);
	const regionNames = new Intl.DisplayNames(
		['en'], { type: 'region' }
	);
	const formatData = (data) => {

		const formattedData = [];
		for (let i = 0; i < data.length; i++) {
			try {
				let array = {
					id:
						data[i].id !== "unknown" && data[i].id !== "Other" && data[i].id !== "null"
							? regionNames.of(countryToAlpha2(data[i].id)) :
							data[i].id,
					data: data[i].data,
				};
				formattedData.push(array)

			} catch (error) {
				console.error(data[i].id);
			}
		}
		return formattedData
	};

	let preFormattedData = formatData(props?.data !== undefined && Object.values(props?.data));

	let combinedData = [];

	preFormattedData.forEach((item) => {
		if (item.id === 'unknown' || item.id === 'null') {
			if (combinedData.length === 0) {
				combinedData = JSON.parse(JSON.stringify(item.data));
			} else {
				item?.data.forEach((dataItem, index) => {
					combinedData[index].y += dataItem.y;
				});
			}
		}
	});

	let formattedData = preFormattedData.filter((item) => item.id !== 'null' && item.id !== 'unknown');

	formattedData.push({ id: 'Unknown', data: combinedData });

	const MyResponsiveLineGraph = React.useMemo(() => {
		let sum = 0;
		for (let key in preFormattedData) {
			for (let value in preFormattedData[key].data) {
				sum += preFormattedData[key].data[value].y
			}
		};

		if (formattedData.length !== 0 && sum !== 0) {
			return (
				<ResponsiveLine
					data={formattedData}
					margin={{ top: 10, right: 40, bottom: 75, left: 40 }}
					xScale={{ type: 'point' }}
					yScale={{
						type: 'linear',
						min: 'auto',
						max: 'auto',
						stacked: false,
						reverse: false
					}}
					curve="monotoneX"
					colors={{ scheme: 'nivo' }}
					yFormat=" >-.2f"
					axisTop={null}
					axisRight={null}
					axisBottom={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 25,
						legendPosition: 'middle',
						legendOffset: 36,
						format: (value) => {
							Object.values(props.data)[0] = !Object.values(props.data);
							return (Object.values(props.data) && pathname !== "/wtp/today") ? moment(value).format("Do MMM") : value[3] === "0" ? value : ""
						}
					}}
					axisLeft={{
						tickSize: 5,
						tickPadding: 5,
						tickRotation: 0,
						legendOffset: -40,
						legendPosition: 'middle',
						format: (value) => value % 2 === 0 ? value : ""
					}}
					pointSize={10}
					pointColor={{ theme: 'background' }}
					pointBorderWidth={2}
					pointBorderColor={{ from: 'serieColor' }}
					pointLabelYOffset={-12}
					useMesh={true}
					enableSlices="x"
					sliceTooltip={(e) => <CustomTooltip data={e} graphRef={graphRef} />}
				/>
			)
		} else {
			return (
				<TrafficMessage message="There has been no traffic in the selected time period." />
			)
		};

	}, [props.data])

	return (
		<>
			<div className="graph-header" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				<div className="graph-heading">Countries</div>
				<GraphsToolTip message="This summary shows which countries the bots are coming from." />
				{maxIcon &&
					<div className="max-icon" onClick={handleMaximizeClick} title="Expand Graph">
						<MaxIcon />
					</div>
				}
			</div>
			<div style={{ height: '100%', width: '100%' }} ref={graphRef} onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
				{MyResponsiveLineGraph}
			</div>
			{isModalOpen &&
				<GraphModal
					isOpen={isModalOpen}
					onClose={handleMaximizeClick}
					graph={MyResponsiveLineGraph}
					title="Countries"
				/>
			}
		</>
	)
};

export default CountriesLineGraph;