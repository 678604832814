import React from "react";
import "./RemoveWebsite.css";
import api from "../../../../veracityapi";
import { useDispatch } from "react-redux";
import { websiteList } from "../../../../Redux/actions";

const RemoveWebsite = (props) => {
	const dispatch = useDispatch();
	const companyName = localStorage.getItem("companyName");
	const [modalState, setModalState] = React.useState("confirm");



	const handleCloseModal = (deleted = false) => {		
		if(deleted && (api.getWebsiteGUID() === props.removeWebsite.guid)){
			props.setBackToSelect(true);
		};
		props.setRemoveWebsite(false);
	};

	const removeWebsite = () => {
		setModalState('loading');
		api.DELETE(`/website/${props.removeWebsite.guid}`)
			.then((resp) => {
				api.GET(`/website/customer/${api.getCustomerGUID()}`)
					.then(resp => {
						dispatch(websiteList(resp.items));
						localStorage.setItem("websitesNumber", resp.items.length);
						setModalState('complete');			
					}).catch((error) => {
						setModalState('failed');
					})
			}).catch((error) => {
				setModalState('failed');
			});
	};

	return (
		<div>
			{modalState === "confirm" ?
				<div className="add-website-modal">
					<div className="add-website-modal-content">
						<div className="add-website-modal-header">
							<h3 className="add-website-modal-name">Remove website</h3>
							<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
						</div>
						<div className="remove-website-modal-body">
							<div>
								<span>This will remove {props.removeWebsite.website_name} from {companyName}.</span>
							</div>
							<div className="remove-website-modal-button-box">
								<button className="remove-button" onClick={removeWebsite}>Confirm</button>
								<button className="edit-button" onClick={handleCloseModal}>Cancel</button>
							</div>
						</div>
					</div>
				</div>
				:
				modalState === "loading" ?
					<div className="add-website-modal">
						<div className="add-website-modal-content">
							<div className="add-website-modal-header">
								<h3 className="add-website-modal-name">Remove website</h3>
								<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
							</div>
							<div className="remove-website-modal-body">
								<div>
									<span>Processing please wait.</span>
								</div>
							</div>
						</div>
					</div>
					:
					modalState === "complete" ?
						<div className="add-website-modal">
							<div className="add-website-modal-content">
								<div className="add-website-modal-header">
									<h3 className="add-website-modal-name">Remove website</h3>
									<h3 className="add-website-close-modal" onClick={()=>handleCloseModal(true)}>X</h3>
								</div>
								<div className="remove-website-modal-body">
									<div>
										<span>Website removed successfully.</span>
									</div>
									<div className="remove-website-modal-button-box">
										<button className="edit-button" onClick={()=>handleCloseModal(true)}>Close</button>
									</div>

								</div>
							</div>
						</div>
						:
						<div className="add-website-modal">
							<div className="add-website-modal-content">
								<div className="add-website-modal-header">
									<h3 className="add-website-modal-name">Remove website</h3>
									<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
								</div>
								<div className="remove-website-modal-body">
									<div>
										<span>Error, please refresh and try again.</span>
									</div>

								</div>
							</div>
						</div>
			}
		</div>
	);
};

export default RemoveWebsite;