import React from "react";
import "./ActiveCampaignPanel.scss";
import Table from "../Table/TableClassic";
import Loader from "../../../SharedComponents/Loader/Loader";
import NoContent from "../../../SharedComponents/NoContent/NoContent";

const ActiveCampaignPanelClassic = ({ adType, className, data, loading, baseURL }) => {

    const values = data && data?.[adType]?.values;
    const total = data && data?.[adType]?.summary;
    const headers = values?.[0] ? Object.keys(values?.[0]) : [];

    return (
        <div className={`active-campaign-panel ${className}`} >
            {loading ? <Loader /> :
                <>{values?.length ? <Table values={values} adType={adType} keys={headers} total={total} baseURL={baseURL} /> : <NoContent />}</>
            }
        </div>
    )
}
export default ActiveCampaignPanelClassic;
