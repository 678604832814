import React from "react";
import "./LicenceKey.scss";
// import styles from "../AdFraudProtection/AdNetworkConnection/"
import { useSelector } from "react-redux";
import Loader from "SharedComponents/Loader/Loader";
import moment from "moment";

const LicenceKey = () => {
	const stateKeys = useSelector(state => state?.websiteDetails?.data?.licenceKeys) ?? [];
	const websiteName = useSelector(state => state?.websiteDetails?.data.websiteDetails?.primary_hostname);
	// const keyConvert = (key) =>{
	// 	switch(true){
	// 		case key.terminated:
	// 			return "Cancelled";
	// 		case key.end_date!== null && key.end_date < Date.now():
	// 			return "Expired";
	// 		case key.start_date > Date.now():
	// 			return "Upcoming";
	// 		case key.type === "Full":
	// 			return "Active";
	// 		case key.type === "Trial":
	// 			return "Trial";			
	// 	};
	// };
	const loading = false;
	// const mockData = [
	// 	{
	// 	website: "veracitytrustnetwork.com",
	// 	licence_key: "abc12-def45-ghi67-jkl90-mno12",
	// 	sessions_hour: "5000",
	// 	sessions_day: "5000",
	// 	sessions_month: null,
	// 	start_date: "10/11/2024",
	// 	end_date: "02/12/2025",
	// 	licence_type: "Cancelled"
	// 	},
	// 	{
	// 		website: "veracitytrustnetwork.com",
	// 		licence_key: "abc12-def45-ghi67-jkl90-mno69",
	// 		sessions_hour: null,
	// 		sessions_day: null,
	// 		sessions_month: null,
	// 		start_date: "10/11/2024",
	// 		end_date: null,
	// 		licence_type: "Active"
	// 	},
	// ]

	const timeSplit = (date) => {
		if (date === null) return "—"
		const dateOnly = date.split('T')[0];
		return dateOnly.replaceAll("-", "/")
	}

	return (
		<div className="content-div content-div--LicenceKey">
			<h3>Your Licence Keys</h3>
			{loading ?
				<Loader />
				:
				<table className="licence-keys-table">
					<thead>
						<tr>
							<th>Licenced Website</th>
							<th>Licence Key</th>
							<th>Session Limits</th>
							<th>Start Date</th>
							<th>End Date</th>
							<th>Licence Type</th>
						</tr>
					</thead>
					<tbody>
						{stateKeys.map((licence, index) => (
							<tr id={licence.licence_key}>
								<td>{websiteName}</td>
								<td><b>{licence.licence_key}</b></td>
								{(licence.max_hourly_sessions !== null || licence.max_daily_sessions !== null || licence.max_monthly_sessions !== null) ?
									<td>{licence.max_hourly_sessions != null && <p>{(+licence.max_hourly_sessions).toLocaleString()} sessions / hour</p>}{licence.max_daily_sessions != null && <p>{(+licence.max_daily_sessions).toLocaleString()} sessions / day</p>}{licence.max_monthly_sessions != null && <p>{(+licence.max_monthly_sessions).toLocaleString()} sessions / month</p>}</td>
									: <td><p className="inf">{`\u221E`}</p></td>}
								<td>{moment(licence.start_date).format("Do MMM YYYY")}</td>
								<td>{moment(licence.end_date).isValid() ? moment(licence.end_date).format("Do MMM YYYY") : "-"}</td>
								<td ><div className={`licenceKeys-typeTag licenceKeys-typeTag--${licence.status}`}>{licence.status}</div></td>
							</tr>
						))

						}
					</tbody>

				</table>
			}
		</div>
	);
};

export default LicenceKey;
