import api from "../../../../API/BeaconAPI";
import { BaselineDataAct } from "../../../../Redux/actions";
import { NO_BASELINE_RUN } from "../../Constants";

export default function ({ adType, campaignId = -1, linkId = -1 }, dispatch) {
	const websiteID = api.getWebsiteId();
	if (!adType) {
		return false;
	}

	const handleRequest = (url, resolve, reject) => {
		api.GET(url).then(res => {
			resolve(
				res.response
			);
		}).catch((error) => {
			console.log("Baeline Data Error: ", error);
			reject(error);
		});
	}

	const getClicks = new Promise((resolve, reject) => {
		let url = `3.0/graph/${websiteID}/pd/links/${adType}`
		if (campaignId > -1) {
			url = `3.0/graph/${websiteID}/pd/campaigns/${campaignId}/${adType}`;
		} else if (linkId > -1) {
			url = `3.0/graph/${websiteID}/pd/links/${linkId}/${adType}`
		}
		handleRequest(url, resolve, reject);
	});

	const getBaselineData = new Promise((resolve, reject) => {
		let url = `3.0/website/${websiteID}/pd/campaigns/${adType}`;
		if (campaignId > -1) {
			url = `3.0/website/${websiteID}/pd/campaigns/${campaignId}/links/${adType}`;
		} else if (linkId > -1) {
			url = `3.0/link/${linkId}/website/${websiteID}/${adType}`;
		}
		handleRequest(url, resolve, reject);
	});

	const processDataCreateSummary = (data) => {
		const summary = {};
		const headers = data[0] && Object.keys(data[0]) || [];
		headers.map(i => {
			if (typeof data[0][i] == 'number') {
				summary[i] = data.reduce((a, o) => a + (o[i] ?? 0), 0);
			} else if (typeof data[0][i] == 'string') {
				summary[i] = ''
			}
		})

		summary['bot_percentage'] = summary?.clicks > 0 ? parseFloat((summary?.bots / summary?.clicks * 100).toFixed(1)) : 0;
		summary['bot_percentage_classic'] = summary?.clicks > 0 ? parseFloat((summary?.bots_classic / summary?.clicks * 100).toFixed(1)) : 0;
		summary['human_percentage'] = summary?.clicks > 0 ? parseFloat((summary?.humans / summary?.clicks * 100).toFixed(1)) : parseFloat((summary?.humans / summary?.visits * 100).toFixed(1));
		summary['human_percentage_classic'] = summary?.clicks >= 0 ? parseFloat((summary?.visits / summary?.clicks * 100).toFixed(1)) : 0;
		summary['human_click_cost'] = summary?.visits >= 0 ? parseFloat((summary?.spend / summary?.humans).toFixed(2)) : 0;
		summary['human_click_cost_classic'] = summary?.visits >= 0 ? parseFloat((summary?.spend / summary?.visits).toFixed(2)) : 0;
		summary['invalid_clicks_percentage'] = summary?.clicks >= 0 ? parseFloat((summary?.invalid_clicks / summary?.clicks * 100).toFixed(1)) : 0;
		return summary;
	}
	return new Promise((resolve, reject) => {
		Promise.all([getClicks, getBaselineData]).then(([clicks, values]) => {
			let data = {};
			if (clicks.constructor.name === 'String' || (clicks.length === 0 && values.data.length == 0)) {
				data[adType] = NO_BASELINE_RUN;
			} else {
				const baselineStatus = Object.values(clicks).indexOf('Running') == -1 ? 'Complete' : 'In progress';
				let summary = processDataCreateSummary(values.data);
				data[adType] = {
					values: values?.data, summary, clicks, baselineStatus: baselineStatus, period: {
						...values?.time
					}
				};
			}
			dispatch(BaselineDataAct({ data }));
			resolve(true);
		}).catch(error => {
			api.handleError(error);
			reject(error);
		});
	});
};