import { AdFraudIcon, TodayIcon, AnalyticsIcon, BotActivityIcon, OverviewIcon, CurrentIcon, BaslineIcon, AffiliatesIcon, ProtectionTabIcon, InSightsIcon } from "./icons";
export const MenuData = [
    {
        id: 4,
        title: "Web Threat",
        icon: <ProtectionTabIcon />,
        children: [{
            id: 1,
            title: 'Today',
            path: '/wtp/today',
            icon: <TodayIcon />

        },
        {
            id: 2,
            title: 'Bot Activity',
            path: '/wtp/bot-activity',
            icon: <BotActivityIcon />

        },
        {
            id: 3,
            title: 'Performance',
            path: '/wtp/performance',
            icon: <AnalyticsIcon />

        }]
    },
    {
        id: 4,
        title: "Ad Fraud",
        icon: <AdFraudIcon />,
        children: [
            {
                id: 2,
                title: "Current",
                path: "/afp/current",
                icon: <CurrentIcon />,

            }
        ]
    },
    {
        id: 4,
        title: "Affiliates",
        icon: <AffiliatesIcon />,
        children: [
            {
                id: 1,
                title: "Affiliates",
                path: "/affiliates",
                icon: <AffiliatesIcon />,

            }
        ]
    },
    {
        id: 4,
        title: "Admin Nav",
        icon: <InSightsIcon />,
        children: [{
            id: 1,
            title: 'Today',
            path: '/wtp/today',
            icon: <TodayIcon />

        },
        {
            id: 2,
            title: 'Bot Activity',
            path: '/wtp/bot-activity',
            icon: <BotActivityIcon />

        },
        {
            id: 3,
            title: 'Performance',
            path: '/wtp/performance',
            icon: <AnalyticsIcon />

        },
        {
            id: 4,
            title: "Baseline",
            path: "/afp/baseline",
            icon: <BaslineIcon />,
        },
        {
            id: 5,
            title: "Current",
            path: "/afp/current",
            icon: <CurrentIcon />
        },
        {
            id: 6,
            title: "Affiliates",
            path: "/affiliates",
            icon: <AffiliatesIcon />,

        }
        ]
    }
]