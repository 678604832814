const britishNumberFormatter = new Intl.NumberFormat("en-GB");
export const formatNumber = (num = '') => {
    if (num === null || num === undefined) {
        return num;
    }
    num = num.toString();
    if (/^\d+.\d+$/.test(num)) {
        return britishNumberFormatter.format(num);
    } else {
        return num;
    }
};

export const formatCurrency = (num = '') => {
    const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    };
    return Number(num).toLocaleString("en-GB", options);
}

export const decodeCurrency = (str) => {
    return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
        return String.fromCharCode(charCode);
    });
};

export const utcTimeStringToFrontDisplay = (IOSting, timezone = 'UTC') => {
    const tempDate = new Date(new Date(IOSting).getTime() - (new Date(new Date(IOSting).toLocaleString("en-US", { timeZone: timezone })) - new Date(new Date(IOSting).toLocaleString("en-US", { timeZone: 'UTC' })))).toISOString();
    const parts = tempDate.split(/(?:T|\.)/);
    return (`${parts[0]} ${parts[1]}`);
};