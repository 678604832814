import React from "react";
import { useSelector } from "react-redux";
import "./Website.scss"
import WebsiteDetails from "./WebsiteDetails/WebsiteDetails";
import DeleteAccount from "../Website/DeleteAccount/DeleteAccount";
import LicenceKey from "./LicenceKey/LicenceKey";
import AFPStatus from "./AdFraudProtection/AFPStatus";
import api from "../../veracityapi";
import bApi from "../../API/BeaconAPI";
import AdNetworkConnection from './AdFraudProtection/AdNetworkConnection'
const Website = () => {

	const primaryUser = localStorage.getItem("primaryUser") === "true";
	const state = useSelector(state => state.toggle.toggle);
	const isAFPEnabled = localStorage.getItem('isAFPEnabled') === "true" && bApi.getWebsiteId() != null;
	const bAFPConnectionExist = localStorage.getItem("bcn-website-id") != null && localStorage.getItem("isAFPEnabled") === "true";
	const [formStatus, setformStatus] = React.useState(bAFPConnectionExist);
	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};


	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)";
	}, []);

	return (
		<div>
			<div className={state ? "content-active" : "content"}>
				<div className="website-content" >
					<div className="website-heading">Website Settings</div>
					<WebsiteDetails primaryUser={primaryUser} />
					{/* {isAFPEnabled && <AFPStatus formStatus={formStatus} setformStatus={setformStatus} />}  */}
					{primaryUser && <DeleteAccount primaryUser={primaryUser} />}
					{formStatus && <AdNetworkConnection />}
					{<LicenceKey />}
				</div>
			</div>
		</div>
	);
};

export default Website;