import axios from "axios";
import _sodium from "libsodium-wrappers";
import api from "../veracityapi";
import { decodeCurrency } from "../Utils/utils";
import { store } from "Redux/store";
let sodium = null;

const BeaconAPI = function (options = {}) {
	let veracityWebsiteGUID = "";
	let authToken = "";
	let refreshToken = "";
	let isRefreshing = false;
	let lastRefresh = null;
	let errorState = false;
	let readyState = false;
	let adplatforms = [];
	let trycount = 0;
	let waitingForWTP = false;
	const CLIENT_KEY = "5ed521d91ab0c43201525b2699407460";
	const CLIENT_SECRET = "b6c28732b0466a35e7da55668105241bbe7efc06752623d1dd066754e5b795ef";
	const BeaconRequest_publickey = "7d7a73ec761069c74e4a1ee34d5bcc3913ad2a9b92d98925a794c0f44b218f3e";
	let bLSUpdateRequired = true;

	let hostname = "v5api.thisisbeacon.com";
	let isDevSystem = false;
	if (document.location.hostname === "qa.platform.veracitytrustnetwork.com") {
		hostname = "qaapi.thisisbeacon.com";
		isDevSystem = true;
	};

	if (document.location.hostname === "localhost") {
		//hostname = "deepak-api.beaconsoft.ltd"
		hostname = "qaapi.thisisbeacon.com";
		isDevSystem = true;
	};

	if (isDevSystem) {
		axios.defaults.headers.common["Dev-Authorization"] = authToken;
	} else {
		axios.defaults.headers.common["Authorization"] = authToken;
	}
	const config = Object.assign({
		schema: "https",
		hostname: hostname

	}, options);

	const instance = axios.create({
		baseURL: `${config.schema}://${config.hostname}`,
		timeout: 15000,
		validateStatus: (status) => (status === 200),
	});

	const updateFromLocalStorage = () => {
		//removed
	}

	this.disconnect = (reason) => {
		if (typeof reason === "string") {
			localStorage.setItem("disconnect_reason", reason);
		}
	}

	this.tryAgain = (endpoint) => {
		//
	};

	const deleteAFPTokenInWTP = () => {
		//removed
	}
	this.deleteAFPTokenInWTP = deleteAFPTokenInWTP;


	const handleWTPAuth = () => {
		//removed
	}
	this.approve = ({ auth_verify }) => {
		//removed
	}
	this.doAuth = ({ email, password }) => {
		//Removed
	}
	this.logout = (msg) => {
		localStorage.removeItem('bcn-bearer');
		localStorage.removeItem('bcn-refresh');
	}

	this.setWebsiteId = (websiteId) => {
		localStorage.setItem("bcn-website-id", websiteId);
	}

	this.getWebsiteId = () => {
		let websiteId = localStorage.getItem("bcn-website-id");
		return websiteId === "null" ? null : websiteId;
	}

	const getUrl = (endpoint, querystringObj) => {
		return [`${instance.getUri()}/${endpoint}`, new URLSearchParams(querystringObj).toString()].join('?')
	}

	this.HEAD = () => {
		//not implimented
	}

	this.GET = (endpoint, querystringObj) => {
		if (bLSUpdateRequired) {
			updateFromLocalStorage();
		}
		const queryUrl = getUrl(endpoint, querystringObj);


		return new Promise((resolve, reject) => {
			api.GET(queryUrl, {}, {
				validateStatus: (status) => status === 200
			}).then(resp => {
				resolve(resp.item);
			}).catch(err => {
				reject(err);
			});
		});
	}

	this.PATCH = () => {
		//not implimented
	}
	this.DELETE = (endpoint, payload, querystringObj) => {

		const queryUrl = getUrl(endpoint, querystringObj);


		return new Promise((resolve, reject) => {
			api.DELETE(queryUrl, {}, {
				validateStatus: (status) => status === 200
			}).then(resp => {
				resolve(resp.item);
			}).catch(err => {
				reject(err);
			});
		});
	}
	this.POST = (endpoint, payload, querystringObj) => {

		const queryUrl = getUrl(endpoint, querystringObj);


		return new Promise((resolve, reject) => {
			api.POST(queryUrl, payload, {
				validateStatus: (status) => status === 200
			}).then(resp => {
				resolve(resp.item);
			}).catch(err => {
				reject(err);
			});
		});
	}

	this.PUT = () => {
		//not implimented
	}
	this.handleError = (error) => {
		console.log("Beacon API Error: ", error);
	}
	this.setCurrencySymbol = () => {
		const isAFPEnabled = localStorage.getItem("isAFPEnabled") === "true";
		if (isAFPEnabled) {
			this.GET(`3.0/website/${this.getWebsiteId()}/currencySymbol`).then(({ response }) => {
				const currenySymbol = decodeCurrency(response?.website?.currency_symbol || '&#163;');
				localStorage.setItem("bcn-currency-symbol", currenySymbol);
			}).catch(err => {
				localStorage.setItem("bcn-currency-symbol", "£")
			});
		}

	}
	this.getAdplatforms = () => {
		return new Promise((resolve, reject) => {
			if (adplatforms.length == 0) {
				this.GET(`3.0/website/${this.getWebsiteId()}/adplatforms`).then(resp => {
					//adplatforms = resp.response;
					resolve(resp.response);
				}).catch(err => {
					adplatforms = [];
					reject(null);
				})

			}
		});


	}

}

const bApi = new BeaconAPI();
export const bApiV8 = new BeaconAPI({ hostname: 'v6api.thisisbeacon.com' });
export default bApi;