import React from "react";
import { GraphsToolTipIcon } from "../../SVG/GraphsToolTIpIcon";
import ReactHtmlParser from 'react-html-parser';
import './GraphsToolTip.scss';

const GraphsToolTip = (props) => {
    if (Array.isArray(props.message)) {
        return (
            <>
                <div className="tooltip">
                    <GraphsToolTipIcon />
                    <span className="tooltiptext">
                        {props.message.map((message, index) => (
                            <p key={index}>{ReactHtmlParser(message)}</p>
                        ))}
                    </span>
                </div>
            </>
        )
    }
    return (
        <>
            <div className="tooltip">
                <GraphsToolTipIcon />
                <span className="tooltiptext">{props.message}</span>
            </div>
        </>
    )
};

export default GraphsToolTip;