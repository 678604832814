import React, { useMemo } from "react";
import GraphsToolTip from "SharedComponents/GraphsToolTip/GraphsToolTip";
import { ResponsivePie } from "@nivo/pie";
import Loader from "SharedComponents/Loader/Loader";
import TrafficMessage from "SharedComponents/TrafficMessage/TrafficMessage";
import { Link } from "react-router-dom";
import { botRiskHighToLow } from "components/AFP/Constants";
const BotType = ({ formattedData, loading, title }) => {
    const styles = {
        backgroundColor: '#ffffff',
        padding: '6px 9px',
        borderRadius: '2px',
        minWidth: '160px',
        boxShadow: '0 3px 5px rgba(0, 0, 0, .25)',
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        gap: "8px",
    };

    const reformat = (formattedData) => {
        let totalBots = 0;
        for (let i = 0; i < formattedData.length; i++) {
            totalBots += formattedData[i].value;
        }
        for (let i = 0; i < formattedData.length; i++) {
            let unformattedPercentage = (formattedData[i].value / totalBots) * 100
            formattedData[i].percentage = parseFloat(unformattedPercentage.toFixed(1));
        }
        return formattedData;
    }

    const ToolTip = ({ data }) => {
        return (
            <div style={styles}>
                <div>
                    <span style={{ fontWeight: "bolder" }} >{data.label} </span>
                    <div><span style={{ fontWeight: "bolder" }}>{(data.value)}</span> bots - {(data.data.percentage)}%</div>
                </div>
            </div>
        )
    };

    const orderForLegend = botRiskHighToLow;

    const sortedData = [];

    for (const name of orderForLegend) {
        const found = formattedData.find((data) => data.label === name);
        if (found) sortedData.push(found)
    }

    const BotTypePieChart = useMemo(() => {
        return (
            <ResponsivePie
                data={reformat(sortedData)}
                margin={{ top: 20, right: 150, bottom: 40, left: 40 }}
                //sortByValue={true}
                innerRadius={0.35}
                padAngle={2}
                activeOuterRadiusOffset={8}
                borderWidth={2}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            0.2
                        ]
                    ]
                }}
                colors={({ data }) => data.color}
                enableArcLinkLabels={false}
                arcLinkLabelsSkipAngle={14}
                arcLinkLabelsTextOffset={8}
                arcLinkLabelsTextColor={{ theme: 'background' }}
                arcLinkLabelsThickness={2}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={false}
                arcLabelsRadiusOffset={0.6}
                arcLabelsSkipAngle={18}
                tooltip={({ datum }) => <ToolTip data={datum} />}
                legends={[
                    {
                        data: sortedData,
                        anchor: 'right',
                        direction: 'column',
                        translateX: 100,
                        itemsSpacing: 8,
                        itemWidth: 60,
                        itemHeight: 20,
                        itemTextColor: '#999',
                        itemOpacity: 1,
                        symbolSize: 13,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000'
                                }
                            }
                        ]
                    }
                ]}
            />
        )
    }, [formattedData]);

    return (
        <div className="bot-type-container">
            <div className="graph-header" >
                <div className="graph-heading">{title}</div>
                <GraphsToolTip message={<>Classified bot types include: Scraper, Crasher, Faker, Thief, Blocker, Clicker, Pretender, Scout, Scanner, and Ghost. For more information, view our bot type details <Link to="/bot-types" style={{ marginTop: 10, color: "white" }} >here</Link>.</>} />
            </div>
            {formattedData.length !== 0 ? <>

                {loading ? <Loader /> : <div style={{ height: '100%', width: '100%' }}>
                    {BotTypePieChart}
                </div>}

            </> : <TrafficMessage message="There are currently no bots on the website." />}
        </div>
    )
};


export default BotType;