import React from "react";
import "./DeleteAccount.scss";
// import Permissions from "../../../SharedComponents/PermissionsMessage/Permissions";

const DeleteAccount = () => {

	const numbersOfWebsites = localStorage.getItem("websitesNumber");
	let condition = numbersOfWebsites === "1";
	// const [displayPermissionsMessage, setDisplayPermissionsMessage] = React.useState(false);

	// const handlePermissions = () => {
	// 	if (condition) {
	// 		setDisplayPermissionsMessage(true)
	// 		setTimeout(() => {
	// 			setDisplayPermissionsMessage(false)
	// 		}, 8000);
	// 	};
	// };

	return (
		<div className="delete-account-section">
			<h3>Delete {condition ? "Website" : "Website"}</h3>
			{!condition ?
				<>
					<p>Type DELETE in the field below to confirm you want to delete your {condition ? "Website" : "Website"}.</p>
					<b>Please note, this can not be undone.</b>
					<div className="delete-section" >
						<input
							className="delete-input"
							placeholder="type DELETE"
							disabled={condition ? true : false}
						/>
						<button className={condition ? "delete-account-disable" : "delete-account-button"}>Delete {condition ? "Website" : "Website"}</button>
					</div></> :
				<p>You cannot delete this website. You must have at least one website assigned to your company.</p>

			}
			{/* {displayPermissionsMessage && <Permissions permissionMessage="You can't delete this website, there is only website for you! contact company" />} */}
		</div>

	);
};
export default DeleteAccount;