import React from "react";
import TrafficMessage from "../../../../../SharedComponents/TrafficMessage/TrafficMessage";
import { useSelector } from "react-redux";

const ActivePages = (props) => {
	const websiteStatus = useSelector(state => state.websiteDetails.data.websiteDetails.status);
	const botsString = websiteStatus === "protect" ? "Bot Landings Stopped" : "Bots Currently Active";

	const ActivePagesData = () => {
		const data = props.activePagesData.filter((ele) => ele.id !== "Other");
		return (
			<div className="active-pages">
				<div className="active-page-table-container">
					{
						data?.length !== 0 ?
							<table className="active-page-table">
								<thead>
									<tr>
										<th> Page </th>
										<th>{botsString}</th>
									</tr>
								</thead>
								<tbody>
									{
										data.map((obj) => {
											return (
												<>
													<tr key={obj.id} >
														<td title={decodeURI(obj.id)} style={{ overflowWrap: "anywhere" }}>{decodeURI(obj.id)}</td>
														<td title={obj.id}>{obj.value}</td>

													</tr>

												</>
											)

										})
									}
								</tbody>
							</table> :
							<TrafficMessage message="There are currently no bots on the website." />
					}
				</div>
			</div>
			// </div>
		);
	};

	return (
		<>
			<ActivePagesData />
		</>
	);
};

export default ActivePages;