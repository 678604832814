import React from "react";
import "./AdnetworkPicker.scss"
import { useDispatch } from "react-redux";

const AdnetworkPicker = ({ adNetworks, dispatchEvent, adPlatformData }) => {

    const dispatch = useDispatch();
    const getUpdatedState = (val) => {
        let adNetWork = [];
        if (adNetworks) {
            if (adNetworks.includes(val.id)) {
                adNetWork = adNetworks.filter((id) => id !== val.id);
            } else {
                adNetWork = [...adNetworks, val.id];
            }
            dispatch({ type: dispatchEvent, payload: adNetWork });
        }
    };

    return (
        <div className="adn-main" >
            {adPlatformData?.availablePlatforms?.map((val) => {
                return (
                    val ? <div key={val.id} className={`option ${adNetworks.includes(val.id) ? 'selected' : ''}`} onClick={() => {
                        getUpdatedState(val)
                    }}><span>{val.label}</span></div> : ''
                );

            })}
        </div>
    );
}

export default AdnetworkPicker;