import React, { useState } from "react";
import ValueCard from "../../ValueCard/ValueCard";
import ActiveCampaignPanelClassic from "../../ActiveCampaigns/ActiveCampaignPanelClassic";
import ClicksVisitsLineGraphClassic from "../../ClicksVisitsLineGraph/ClickVisitsLineGraphClassic";
import './BaselineTile.scss'
import Tile from "../../../../SharedComponents/Layout/Tile/Tile";
import { useSelector, useDispatch } from "react-redux";
import BaselineData from "../BaselineData/BaselineData";
import { formatNumber, formatCurrency } from "../../../../Utils/utils";
import { utcTimeStringToLocalTimeString } from "../../../../SharedComponents/DateManager"
import { Adnetworks, NO_BASELINE_RUN } from "../../Constants";
import NoContent from "../../../../SharedComponents/NoContent/NoContent";
import GraphsToolTip from "../../../../SharedComponents/GraphsToolTip/GraphsToolTip";
import Loader from "../../../../SharedComponents/Loader/Loader";
import TrafficFunnelClassic from "../../TrafficFunnel/TrafficFunnelClassic";
import { MaxIcon } from "../../../../SVG/MaxIcon";
import GraphModal from "../../../../SharedComponents/GraphModal/GraphModal";
import Breadcrumb from "../../Breadcrumb";

const BaselineTileClassic = ({ campaignId, linkId, adType = -1 }) => {
    adType = parseInt(adType);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [supportLink, setSupportLink] = React.useState("");
    const primaryUser = localStorage.getItem("primaryUser") === "true";
    const currencySymbol = localStorage.getItem("bcn-currency-symbol");
    const adNetworks = useSelector(state => state.baselineData.selectedNetworks) || [];
    const baselineData = useSelector(state => state.baselineData.data) || {};
    const dispatch = useDispatch();
    const drilldownPage = (campaignId || linkId);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [maxIcon, setMaxIcon] = React.useState(false);
    const handleMaximizeClick = () => {
        setIsModalOpen(!isModalOpen);
    };
    const [baseURL, setBaseURL] = useState(`/afp/baseline/campaign`);
    const getTitle = () => {
        if (baselineData[adNetworks[0]] == NO_BASELINE_RUN) return "No data available";
        const timePeriod = baselineData?.[adNetworks[0]]?.period
        if (timePeriod?.start)
            return utcTimeStringToLocalTimeString(timePeriod?.start, null, 'Do MMM YYYY') + ' to ' + utcTimeStringToLocalTimeString(timePeriod?.end, null, 'Do MMM YYYY');
        else
            return 'No data available'
    };

    const getErrorMessage = (error) => {

        if (primaryUser && error?.response?.status === 403) {
            setSupportLink("Website Settings")
            setError("The connection to your Veracity Ad Fraud Protection account has expired. Visit Website Settings to reconnect.")
        } else if (!primaryUser && error?.response?.status === 403) {
            setError("The connection to your Veracity Ad Fraud Protection account has expired. The primary user on your account must reconnect in Website Settings.")
        }
        else {
            setSupportLink("Contact Support")
            setError("Sorry, we’re having trouble loading your Veracity Ad Fraud Account, if this problem persists please contact us for support.")
        }

    };



    const updateData = () => {
        setBaseURL(`/afp/baseline/campaign`);
        if (campaignId) {
            setBaseURL(`/afp/baseline/link`);
        }
        setLoading(true);
        adNetworks.map(i => {
            if (adType === -1 || adType === i) {
                BaselineData({ adType: i, campaignId, linkId }, dispatch).then(() => {
                    setLoading(false);
                    setError('');
                }).catch((e) => {
                    setLoading(false);
                    setError(`Failed to change time range : ${e}`)
                });
            }
        })
    };

    React.useEffect(() => {
        adNetworks.map(i => {
            if (!baselineData[i]?.summary) {
                setLoading(true);
                BaselineData({ adType: i, campaignId, linkId }, dispatch).then(() => {
                    setLoading(false);
                    setError('');
                }).catch(e => {
                    setLoading(false);
                    setError(`Failed to change time range : ${e}`)
                });
            }
        })

    }, [adNetworks.length]);
    React.useMemo(() => {
        updateData();
    }, [campaignId, linkId])

    return (
        <Tile error={error} showAdnetworkPicker={!drilldownPage} supportLink={supportLink} graph={drilldownPage ? "Baseline" : 'Baseline Period - ' + getTitle()} adNetworks={adNetworks} dispatchEvent="UPDATE_BASELINE_ADNETWORK" DropFunc={() => { }} DragFunc={() => { }} dragged={null} enableDateFilter={false} DisplayDashboard={false}>

            {adNetworks.map((i, j) => {
                if ((campaignId || linkId) && i !== adType) return null;
                const hP = baselineData[i]?.summary?.human_percentage_classic;
                const visits = baselineData[i]?.summary?.visits;
                const adLabel = Adnetworks.find(nt => nt.id === i)?.label;
                const adIcon = Adnetworks.find(nt => nt.id === i)?.icon;
                const adHeadingBackGroundColor = Adnetworks.find(nt => nt.id === i)?.backgroundColor;
                const effectiveSpend = Math.round(baselineData[i]?.summary?.spend - baselineData[i]?.summary?.wasted_spend_classic);
                const engagementPercentage = (baselineData[i]?.summary?.engaged / baselineData[i]?.summary?.clicks * 100 || 0).toFixed(1)
                const graphData = baselineData[i]?.clicks;
                const baselineStatus = baselineData[i]?.baselineStatus;
                return (
                    <div>
                        {drilldownPage ? <Breadcrumb baseURL={'/afp/baseline'} linkId={linkId} adType={adType} adIcon={adIcon} values={baselineData[i].values} /> : <h2 className="adNetworkHeading" style={{ background: adHeadingBackGroundColor }}>{adIcon}{adLabel}</h2>}
                        {baselineData[i] !== NO_BASELINE_RUN ? <div key={j} className="grid">
                            <div className='item-1 graph'>
                                <div className="graph-header">
                                    <div className="graph-heading">Engagement funnel</div>
                                    <GraphsToolTip message={["Paid clicks:<br/>the number of clicks on your ads as reported by your ad network.", "Human visits:<br/>verified humans who have landed from your ads.", "Engaged humans:<br/>the number of humans who have engaged with the content on your landing page."]} />
                                </div>{loading ? <Loader /> : <TrafficFunnelClassic data={baselineData[i]?.summary} />}
                            </div><div className="bitem-3 ">

                                <div className="graph" onMouseEnter={() => setMaxIcon(true)} onMouseLeave={() => setMaxIcon(false)}>
                                    <div className="graph-header">
                                        <div className="graph-heading">{`Baseline Traffic (${baselineStatus})`}</div>
                                        <GraphsToolTip message="A daily view of all clicks and visits during the baseline period." />
                                        {maxIcon &&
                                            <div className="max-icon" style={{ marginTop: 7 }} onClick={handleMaximizeClick} title="Expand Graph">
                                                <MaxIcon />
                                            </div>
                                        }
                                    </div>
                                    <ClicksVisitsLineGraphClassic data={baselineData[i]?.clicks} showMarker={baselineStatus === 'In progress'} />
                                    {isModalOpen &&
                                        <GraphModal
                                            isOpen={isModalOpen}
                                            onClose={handleMaximizeClick}
                                            graph={<ClicksVisitsLineGraphClassic data={graphData} showMarker={baselineStatus === 'In progress'} />}
                                            title={`Baseline Traffic (${baselineStatus})`}
                                        />
                                    }
                                </div>
                            </div>
                            {!linkId && <ActiveCampaignPanelClassic className='bitem-4' adType={i} data={baselineData} baseURL={baseURL} />}
                            <div className="item-5" >
                                <ValueCard className="small" loading={loading} title={hP ? `${hP}%` : null} subTitle={visits ? `Humans: ${formatNumber(visits)}` : null}></ValueCard>
                                <ValueCard className="small" loading={loading} title={` ${formatNumber(engagementPercentage)}%`} subTitle={'Engagement Percentage'}></ValueCard>
                                <ValueCard className="small" loading={loading} title={`${currencySymbol}${formatCurrency(baselineData[i]?.summary.spend)}`} subTitle={'Spend'}></ValueCard>
                                <ValueCard className="small" loading={loading} title={`${currencySymbol}${formatCurrency(effectiveSpend)}`} subTitle={'Effective Spend'}></ValueCard>

                            </div>
                        </div> : <NoContent message={`No baseline was taken for ${adLabel}`} />}
                    </div>
                )
            }
            )}
        </Tile>
    )
}


export default BaselineTileClassic;
