import React from "react";
import "./AddWebsite.css";
// import Input from "./input";
import { useSelector } from "react-redux";
import api from "../../../../veracityapi";
import { useDispatch } from "react-redux";
import { websiteList } from "../../../../Redux/actions";
import moment from "moment";

const AddWebsite = (props) => {
	const dispatch = useDispatch();
	const [modalState, setModalState] = React.useState("add");
	const [formValues, setFormValues] = React.useState([]);
	const [inpPrimaryHost, setInpPrimaryHost] = React.useState("");
	const [inpWebsiteName, setInpWebsiteName] = React.useState("");
	const customerGuid = localStorage.getItem("selected_customer");
	const [timeZone, setTimeZone] = React.useState(`${localStorage.getItem("timezone")}`);

	const timezones = moment.tz.names();

	const handleCloseModal = () => {
		props.setAddWebsite(false);
	};



	const handleChange = (e, index) => {
		const values = [...formValues];
		values[index].value = e.target.value;
		setFormValues(values);
	};

	const addInput = (e) => {
		e.preventDefault();
		const values = [...formValues];
		values.push({
			label: "",
			id: `${new Date().getTime()}`,
			type: "text",
			value: "",
			className: "add-website-input",
			close: true
		});
		setFormValues(values);
	};

	const removeInput = (e, obj) => {
		e.preventDefault();
		const values = [...formValues].filter(val => val.id !== obj.id);
		setFormValues(values);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setModalState('loading');
		const aliases = formValues.map(obj => obj.value);
		api.POST(`website`, {
			// console.log("TEMP: ", {
			customer_guid: customerGuid,
			primary_hostname: inpPrimaryHost,
			website_name: inpPrimaryHost,
			trello_card_id: "",
			timezone: "",
			aliases: [...aliases]
		})
			.then((resp) => {
				api.GET(`/website/customer/${api.getCustomerGUID()}`)
					.then(resp => {
						dispatch(websiteList(resp.items));
						localStorage.setItem("websitesNumber", resp.items.length);
						setModalState('complete');
					}).catch((error) => {
						setModalState('failed');
					})
			}).catch((error) => {
				setModalState('failed');
			});
	};

	return (
		<div className="add-website-modal">
			{(modalState === "add") &&
				<div className="add-website-modal-content">
					<div className="add-website-modal-header">
						<h3 className="add-website-modal-name">Add new website</h3>
						<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<form className="add-website-form" onSubmit={handleSubmit}
						onKeyDown={(e) => { if (e.key === 'Enter') { e.target.blur(); e.preventDefault(); } }}
					>

						<label for="primaryHost">Website Address: </label>
						<input className="add-website-input" type="url" id="primaryHost" placeholder="https://example.com" value={inpPrimaryHost} onChange={e => setInpPrimaryHost(e.target.value)} required />
						<label for="timezone">Timezone: </label>
						<input className="add-website-input" list="timezones" name="timezone" defaultValue={timeZone} onChange={setTimeZone} />
						<datalist id="timezones" className="datalist">
							{timezones?.map((timezone, index) => (
								<option key={index} value={timezone} />
							))}
						</datalist>
						<button type="submit" className="confirm-add-website-button" >Add</button>
					</form>

				</div>
			}
			{(modalState === "loading") &&
				<div className="add-website-modal-content">
					<div className="add-website-modal-header">
						<h3 className="add-website-modal-name">Add new website</h3>
						<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="add-website-modal-body">
						<div>
							<span>Processing please wait.</span>
						</div>
					</div>
				</div>
			}
			{(modalState === "failed") &&
				<div className="add-website-modal-content">
					<div className="add-website-modal-header">
						<h3 className="add-website-modal-name">Add new website</h3>
						<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="add-website-modal-body">
						<div>
							<span>Error, please refresh and try again.</span>
						</div>
					</div>
				</div>
			}
			{(modalState === "complete") &&
				<div className="add-website-modal-content">
					<div className="add-website-modal-header">
						<h3 className="add-website-modal-name">Add new website</h3>
						<h3 className="add-website-close-modal" onClick={handleCloseModal}>X</h3>
					</div>
					<div className="add-website-modal-body">
						<div>
							<span>Website added successfully.</span>
						</div>
						<div className="remove-website-modal-button-box">
							<button className="edit-button" onClick={handleCloseModal}>Close</button>
						</div>

					</div>
				</div>
			}
		</div>
	);
};

export default AddWebsite;

//http://www.testing.com