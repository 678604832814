import React from "react";
import { useSelector } from "react-redux";
import "./Performance.scss";
import PerformanceTile from "./PerformanceComponent/PerformanceTile/PerformanceTile";
import api from "../../veracityapi";
import Alert from "components/Alert";
import { wtpDisableMessage } from "components/AFP/Constants";
const Analytics = () => {
	const sidetoggle = useSelector(state => (state.toggle.toggle));
	const loadingState = useSelector(store => store?.loadData?.data);
	const detectModes = useSelector(state => state?.detectModes);

	const [loading, setLoading] = React.useState(loadingState);
	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useEffect(() => {
		setLoading(loadingState)
	}, [loadingState]);

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="analytics-container">
			<div className={`display-area ${sidetoggle ? "display-area--small" : "display-area--large"}`}>
				{detectModes?.data?.wtp_status == "off" &&
					<Alert message={wtpDisableMessage}></Alert>
				}
				<PerformanceTile graph={'Human Activity'} loading={loading} setLoading={setLoading} />
				{/* <PerformanceTile graph={'Known Bots'} loading={loading} setLoading={setLoading} /> */}

			</div>
		</div>
	);
};

export default Analytics;