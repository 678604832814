import React from "react";
import "./MessageBar.scss";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import moment from "moment";
const MessageBar = () => {
	const { pathname } = useLocation();
	const stateKeys = useSelector(state => state?.websiteDetails?.data?.licenceKeys) ?? [];
	const active = stateKeys.find((key) => key.status === "Active" && key.terminated === false);
	const trial = stateKeys.find((key) => key.status === "Trial" && key.terminated === false);
	const expired = stateKeys.find((key) => key.status === "Expired");
	const lastTraffic = useSelector((state) => state?.websiteDetails?.data?.websiteDetails?.last_traffic);

	switch (true) {
		case (pathname === '/' || pathname === '/select' || pathname == '/loading'):
			return null;
		case !!active:
			return null;
		case !!trial && lastTraffic === null:
			return (<div className="warning-bar"><b>Trial mode:</b> Setup your Veracity Tag using the instructions below to start your {trial.length_on_start} day trial.</div>);
		case !!trial:
			const trialLimit = {
				timeSpan: null,
				limitValue: null,
			};
			switch (true) {
				case !!trial.max_hourly_sessions:
					trialLimit.timeSpan = "hour";
					trialLimit.limitValue = trial.max_hourly_sessions;
					break;
				case !!trial.max_daily_sessions:
					trialLimit.timeSpan = "day";
					trialLimit.limitValue = trial.max_daily_sessions;
					break;
				case !!trial.max_monthly_sessions:
					trialLimit.timeSpan = "month";
					trialLimit.limitValue = trial.max_monthly_sessions;
					break;
				default:
					break
			};
			return (<div className="warning-bar"><b>Trial mode:</b> Your trial ends {timeSplit(trial.end_date)}.{!!trialLimit.timeSpan && ` Your session limit is ${trialLimit.limitValue}/${trialLimit.timeSpan}, any sessions above this limit will not be protected and won't be shown in your dashboard.`} Activate your full licence before this date to enable protection against malicious bots.</div>);
			break;
		case !!expired:
			return <div className="warning-bar"><b>No Protection:</b> Your licence expired on {timeSplit(expired.end_date)} and your website is not protected. Speak to your sales representative to activate your full licence and enable protection against malicious bots.</div>
			break;
		default:
			return null;
	};
	// if(!trial && !expired ) return null;
	// if(!!trial && !expired) return(<div className="warning-bar"><b>Trial mode:</b> Your trial ends {timeSplit(trial.end_date)}. Activate your full licence before this date to enable protections against malicious bots.</div>);
	// if(!trial && !!expired) return(<div className="warning-bar"><b>Trial mode:</b> Your trial ends 25th December 2024. Activate your full licence before this date to enable protections against malicious bots.</div>);


};

export default MessageBar;

const timeSplit = (date) => {
	return moment(date).format("Do MMMM YYYY");
};
