import React from "react";
import { useParams } from "react-router-dom";
import "./Baseline.scss";
import BaselineTile from "./BaselineComponents/BaselineTile";
import BaselineTileClassic from "./BaselineComponents/BaselineTileClassic";
import api from "../../../veracityapi";
import { classicWebsiteGUIDs } from "../Constants";

const Baseline = () => {
	const props = useParams();
	const websiteGUID = api.getWebsiteGUID();
	const classic = classicWebsiteGUIDs.includes(websiteGUID);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);

	return (
		<div className="analytics-container">
			<div>
				{classic ? <BaselineTileClassic {...props} /> : <BaselineTile {...props} />}
			</div>
		</div>
	);
};

export default Baseline;