import React from "react";
import "./index.scss"

const Alert = ({ message, children }) => {
    return (
        <div className="alert-section">
            <div>Web Threat Protection: Disabled</div>
            {message} {children}
        </div>
    )

};

export default Alert; 