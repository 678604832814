import React from "react";
import "./Select.scss";
import api from "../../veracityapi";
import { SelectHead } from "../../SharedComponents/LoginProcess/LoginHead/LoginHead";
import LoginFoot from "../../SharedComponents/LoginProcess/LoginFoot/LoginFoot";
import { Navigate } from "react-router-dom";
import Loading from "../../SharedComponents/LoadingScreen/Loading";
import { websiteList, detectModes } from "../../Redux/actions";
import { useDispatch } from "react-redux";

const CustomerSelect = () => {
	const dispatch = useDispatch();
	const [customers, setCustomers] = React.useState(null);
	const [selectedCustomer, setSelectedCustomer] = React.useState(null);
	const [websiteGuid, setWebsiteGuid] = React.useState("");
	const [websites, setWebsite] = React.useState(null);
	const adminToolBox = localStorage.getItem("admin");
	const [inputValue, setInputValue] = React.useState("");
	const [websiteInformation, setWebsiteInformation] = React.useState(null);
	
	if (!api.isUserLoggedIn()) {
		api.logout("Fatal Error")
	};

	const showAdminToolBox = {
		display: adminToolBox === "true" ? "block" : "none"
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "#662D91"
	});

	const adminToolBoxInputValue = (e) => {
		if (e.key === "Enter") {
			handleSubmit()
		}
		setInputValue(e.target.value)
	};

	// React.useEffect(() => {
	// 	api.clearStore();
	// }, []);

	const handleSubmit = () => {
		if (inputValue === "") {
			alert("Please enter the website ID")
		} else {
			api.GET(`/admin/website/${inputValue}`)
				.then(res => {
					setWebsiteInformation(res.item)
					dispatch(detectModes({
						wtp_status: res.item.status,
						afp_status: res.item.afp_status,
					}));
				}).catch(err => {
					alert("Wrong Website")
				});
		}

	};

	React.useMemo(() => {
		if (websiteInformation !== null) {
			api.setCustomerGUID(websiteInformation.customer_guid)
			api.setWebsiteGUID(websiteInformation.guid)
			localStorage.setItem("websiteName", websiteInformation.website_name)
			localStorage.setItem("timezone", websiteInformation?.timezone ?? "Europe/London");
			dispatch(detectModes({
				wtp_status: websiteInformation?.status,
				afp_status: websiteInformation?.afp_status,
			}));
		}
	}, [websiteInformation]);


	const onCustomerClick = (event, customer) => {
		localStorage.setItem("companyName", event.target.innerText)
		api.setCustomerGUID(customer.guid);
		localStorage.setItem("pending_package", customer.pending_package_guid)
		localStorage.setItem("primaryUser", customer.primary_user);
		localStorage.setItem("isAFPEnabled", customer.afp_enabled);
		localStorage.setItem("currency_symbol", customer.currency_symbol)
		setSelectedCustomer(customer.guid);
	};

	const onWebsiteClick = (event, key) => {
		for (let i = 0; i < websites.length; i++) {
			if (websites[i].guid === key) {
				localStorage.setItem("timezone", websites[i]?.timezone);
				localStorage.setItem("websiteName", websites[i].website_name);
				dispatch(detectModes({
					wtp_status: websites[i]?.status,
					afp_status: websites[i]?.afp_status,
				}));
			};
		};
		api.setWebsiteGUID(key);
		setWebsiteGuid(key);
	};

	if (customers === null) {
		api.GET("/customer").then(resp => {
			setCustomers(resp.items)
			if (resp.items.length === 1) {
				api.setCustomerGUID(resp.items[0].guid);
				localStorage.setItem("pending_package", resp.items[0].pending_package_guid)
				localStorage.setItem("companyName", resp.items[0].company);
				localStorage.setItem("primaryUser", resp.items[0].primary_user)
				localStorage.setItem("isAFPEnabled", resp.items[0].afp_enabled)
				localStorage.setItem("currency_symbol", resp.items[0].currency_symbol)
				setSelectedCustomer(resp.items[0].guid);
			};
		}).catch((error) => {
			// api.logout("permissions Error.")
		})
	};

	if (websites === null && api.getCustomerGUID() !== null) {
		api.GET(`/website/customer/${api.getCustomerGUID()
			}`).then(resp => {
				dispatch(websiteList(resp.items));
				setWebsite(resp.items);
				localStorage.setItem("websitesNumber", resp.items.length)
				if (resp.items.length === 1) {
					api.setWebsiteGUID(resp.items[0].guid);
					localStorage.setItem("timezone", resp.items[0]?.timezone);
					localStorage.setItem("websiteName", resp.items[0].website_name);
					dispatch(detectModes({
						wtp_status: resp.items[0]?.status,
						afp_status: resp.items[0]?.afp_status,
					}));
				};
			}).catch((error) => {
				// api.logout("permissions Error.")
			})
	};

	if (api.getWebsiteGUID() !== null) {
		return (
			<Navigate to="/loading" />
		);
	};
	if (!api.isUserLoggedIn()) {
		return (
			<Navigate to="/login" />
		);
	};

	if ((customers !== null && api.getCustomerGUID() === null)) {
		return (
			<div className="select-page">
				<SelectHead />
				<div className="select-form">
					<p className="select-text">Select Customer</p>
					{
						customers.map(customer => (
							<div className="customer-select"
								key={
									customer.guid
								}
								onClick={
									(event) => {
										onCustomerClick(event, customer)
									}
								}>
								<span className="customer-heading">
									{
										customer.company
									}</span>
							</div>
						))
					}

				</div>
				<LoginFoot />
			</div>
		);
	};
	if (websites !== null) {
		return (
			<div className="select-page">
				<SelectHead />
				<div className="select-form">
					<p className="select-text">Select Website</p>
					{
						websites.map(website => (
							<div className="website-select"
								key={website.guid} onClick={(event) => { onWebsiteClick(event, website.guid) }}>
								<div className="website-details">
									<span className="website-names">{website.website_name === null ? website.primary_hostname : website.website_name.replace("www.", "")}</span>
								</div>
							</div>
						))
					}
				</div>
				<div className="admin--tools" style={showAdminToolBox}>
					<p className="admin--tools_title">Admin tools</p>
					<div className="admin--tools_form">
						<h2 className="admin--tools_heading">Enter website id</h2>
						<span><input type="text" placeholder="Enter id" onKeyUp={adminToolBoxInputValue} className="admin--tools_input" required></input></span>
						<span><button type="submit" className="admin--tools_button" onClick={handleSubmit}>Submit</button></span>
					</div>
				</div>
				<LoginFoot />
			</div>
		);
	} else {
		return (
			<>
				<SelectHead />
				<Loading />
				<LoginFoot />
			</>

		)
	}
};

export default CustomerSelect;
