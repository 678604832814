import React from "react";
import { useParams } from 'react-router-dom';
import "./Current.scss";
import { classicWebsiteGUIDs } from "../Constants";
import CurrentTile from "./Components/CurrentTile";
import CurrentTileClassic from "./Components/CurrentTileClassic";
import api from "../../../veracityapi";
import DataProcessing from "../DataProcessing";
import { useSelector } from "react-redux";

const Current = () => {
	const props = useParams();
	const websiteGUID = api.getWebsiteGUID();
	const classic = classicWebsiteGUIDs.includes(websiteGUID);
	const hideAFPData = useSelector((state) => state?.websiteDetails?.data?.websiteDetails);

	if (!api.isUserLoggedIn() || api.getCustomerGUID() === null || api.getWebsiteGUID() === null) {
		api.logout("Fatal Error")
	};

	React.useLayoutEffect(() => {
		document.body.style.backgroundColor = "rgba(244, 244, 244, 1)"
	}, []);


	if (hideAFPData?.data_processing) {
		return (
			<DataProcessing
				heading="Data Capture & Assessment"
				text="abbi is busy capturing traffic data and assessing types and patterns. This allows her to self-configure for better bot detection."
			/>
		)
	}

	return (
		<div className="analytics-container">
			{classic ? <CurrentTileClassic {...props} /> : <CurrentTile {...props} />}
		</div>
	);
};

export default Current;