import React from "react";
import "./Region.scss";
import moment from "moment-timezone";

const Region = (props) => {
	const [timezones, setTimezones] = React.useState([]);
	const primaryUser = props.primaryUser;

	React.useEffect(() => {
		const allTimezones = moment.tz.names();
		setTimezones(allTimezones);
	}, []);

	return (
		<div className="region-content">
			<div className="main-region">
				<div>
					<form>
						<div onClick={props.handlePermissions}>
							<div>
								<label htmlFor="timezone">Timezone</label>
							</div>
							<input
								list="timezones"
								name="timezone"
								className="timezone-input"
								onChange={props.handleInputChange}
								defaultValue={props.input.timezone}
								disabled={primaryUser ? false : true}

							/>
							<datalist id="timezones" className="datalist">
								{timezones?.map((timezone, index) => (
									<option key={index} value={timezone} />
								))}
							</datalist>
						</div>
					</form>
				</div>
			</div>

			{primaryUser ? 
			<button className="save-change" id="save-change" onClick={props.handleFormSubmit} disabled={!props.isChanged} style={{backgroundColor: `${!props.isChanged ? "#F4F4F4" : `${props.saveButtonStyle ? "#6EBA7A" : "#662D91"}`}`, borderColor: `${!props.isChanged ? "#F4F4F4" : `${props.saveButtonStyle ? "#6EBA7A" : "#662D91"}`}`}}>Save Changes</button> 
			: 
			<p>Only the company owner can update website details.</p>}
		</div>
	);
};

export default Region;