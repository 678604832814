export const MetaIcon = ({ fill }) => {

    return (
        <svg width="30" height="30" viewBox="0 0 353 235" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M252.946 0C224.051 0 201.463 21.7631 181.016 49.4088C152.918 13.6324 129.42 0 101.299 0C43.9667 0 0.0390625 74.6123 0.0390625 153.585C0.0390625 203.004 23.9471 234.173 63.9925 234.173C92.8146 234.173 113.543 220.585 150.394 156.169C150.394 156.169 165.755 129.042 176.323 110.356C180.026 116.335 183.926 122.777 188.023 129.683L205.304 158.753C238.965 215.083 257.72 234.173 291.705 234.173C330.718 234.173 352.429 202.577 352.429 152.132C352.429 69.4443 307.511 0 252.946 0ZM122.293 138.727C92.4162 185.562 82.0803 196.059 65.4459 196.059C48.3271 196.059 38.1527 181.03 38.1527 154.231C38.1527 96.8991 66.738 38.2751 100.814 38.2751C119.267 38.2751 134.688 48.9324 158.309 82.7477C135.88 117.15 122.293 138.727 122.293 138.727ZM235.054 132.831L214.393 98.3731C208.802 89.2797 203.429 80.9096 198.273 73.2628C216.895 44.5215 232.255 30.2003 250.523 30.2003C288.475 30.2003 318.837 86.0788 318.837 154.716C318.837 180.878 310.268 196.059 292.513 196.059C275.495 196.059 267.366 184.82 235.054 132.831Z" fill={fill} />
        </svg>

    )
};

export const MicrosoftLogo = ({ fill }) => {

    return (
        <svg width="32" height="28" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.714844" y="0.702148" width="20.3159" height="20.3159" fill={fill} />
            <rect x="23.1172" y="0.702148" width="20.3159" height="20.3159" fill={fill} />
            <rect x="23.1172" y="23.0361" width="20.3159" height="20.3159" fill={fill} />
            <rect x="0.714844" y="23.0361" width="20.3159" height="20.3159" fill={fill} />
        </svg>
    )
};

export const GoogleLogo = ({ height, viewBox, fill }) => {

    return (
        <svg width="32" height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.5692 27.4008V21.2501H44.3167C44.5196 22.3221 44.6242 23.5901 44.6242 24.9627C44.6242 29.5774 43.3615 35.2836 39.2918 39.3492C35.3333 43.467 30.2757 45.6632 23.5757 45.6632C11.1573 45.6632 0.714844 35.5581 0.714844 23.1522C0.714844 10.7463 11.1573 0.641113 23.5757 0.641113C30.4458 0.641113 35.3399 3.33407 39.017 6.84407L34.6725 11.1842C32.0357 8.71346 28.4633 6.79178 23.5692 6.79178C14.5007 6.79178 7.40823 14.0928 7.40823 23.1522C7.40823 32.2115 14.5007 39.5126 23.5692 39.5126C29.4513 39.5126 32.8012 37.153 34.9473 35.009C36.6877 33.2704 37.8327 30.7866 38.2842 27.3942L23.5692 27.4008Z" fill={fill} />
        </svg>
    )
};

export const TikTokLogo = ({ height, fill }) => {
    return (
        <svg fill={fill} width="32" height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z" /></svg>
    )
};

export const AdWordsLogo = ({ height, viewBox, fill }) => {
    return (
        <svg fill={fill} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
            <path d="M15.989 1.396h0.032c1.197 0 2.307 0.401 3.197 1.073h0.005c0.615 0.463 1.125 1.057 1.489 1.733l5.953 10.36 0.036 0.083 4.557 7.907c0.485 0.817 0.74 1.755 0.74 2.713 0 0.751-0.156 1.469-0.437 2.12l0.005 0.021c-0.213 0.484-0.495 0.927-0.844 1.328-0.396 0.459-0.864 0.853-1.396 1.156-0.787 0.459-1.683 0.703-2.593 0.713h-0.089c-1.156 0-2.276-0.375-3.197-1.073h-0.005c-0.615-0.463-1.125-1.057-1.489-1.733l-5.953-10.36-0.036-0.083-4.579-7.943c-0.473-0.813-0.719-1.735-0.719-2.677 0-0.563 0.088-1.109 0.251-1.62l0.009-0.032 0.016-0.041c0.068-0.208 0.156-0.448 0.156-0.448 0.208-0.479 0.495-0.921 0.833-1.312l0.084-0.099 0.020-0.021v-0.005h0.005l0.011-0.016 0.011-0.004c0.359-0.396 0.787-0.745 1.276-1.027 0.787-0.459 1.683-0.703 2.593-0.713zM5.344 30.604h-0.079c-0.911-0.011-1.807-0.255-2.593-0.713-0.532-0.303-1-0.697-1.396-1.156-0.349-0.401-0.631-0.844-0.844-1.328l0.005-0.021c-0.292-0.667-0.437-1.391-0.437-2.12 0-0.995 0.271-1.921 0.74-2.713l4.557-7.907 0.036-0.083 4.052-7.047c0.099 0.859 0.364 1.703 0.792 2.473l0.077 0.131 4.5 7.807v-0.015l0.089 0.192 0.385 0.677-5.183 9.016c-0.364 0.676-0.875 1.271-1.489 1.733h-0.005c-0.921 0.699-2.041 1.073-3.197 1.073z" />
        </svg>
    )
};

export const DV360Logo = ({ height, fill }) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill={fill} height={height}
            viewBox="0 0 2192.7 2428.1">
            <g>
                <path class="st0" d="M1730.6,949.3c142.5-86.5,328.1-41.1,414.5,101.4c86.5,142.5,41.1,328.1-101.4,414.5
		c-3.8,2.3-7.6,4.5-11.4,6.6L460.2,2377.1c-142.5,86.5-328.1,41.1-414.6-101.4c-86.5-142.5-41.1-328.1,101.4-414.6
		c3.8-2.3,7.6-4.5,11.4-6.6L1730.6,949.3z"/>
                <path class="st1" d="M601,303.7C601.7,137.4,467.4,1.9,301.1,1.2c-21.4-0.1-42.7,2.1-63.7,6.5C96.5,42.3-2,169.5,0,314.7v1798.1
		l600.4,10.9L601,303.7z"/>
                <path class="st2" d="M1737.4,1472.9c145.3,75.3,324.1,27.1,411.9-111.1c84.4-142,37.7-325.6-104.3-410c-1.1-0.6-2.1-1.2-3.2-1.9
		L459.2,43C318.3-42.1,135,3.1,49.8,144c-2.1,3.5-4.2,7.1-6.2,10.7C-40,297.8,8.2,481.7,151.3,565.4c1,0.6,2,1.1,3,1.7
		L1737.4,1472.9z"/>
                <path class="st0" d="M2031.4,1475L600.5,2274.3v-686.6l1135.4-633.1c150.5-83.4,340.2-29,423.6,121.6c7.8,14,14.4,28.6,19.9,43.6
		C2222,1258.3,2159.8,1407.6,2031.4,1475z"/>
                <circle class="st3" cx="300.8" cy="2127.8" r="300.2" />
            </g>
        </svg>

    )
};

export const LinkedInLogo = ({ height, fill }) => {
    return (
        <svg fill={fill} height={height} viewBox="0 0 20 20" >

            <rect x="0" fill="none" width="20" height="20" />

            <g>

                <path d="M2.5 18h3V6.9h-3V18zM4 2c-1 0-1.8.8-1.8 1.8S3 5.6 4 5.6s1.8-.8 1.8-1.8S5 2 4 2zm6.6 6.6V6.9h-3V18h3v-5.7c0-3.2 4.1-3.4 4.1 0V18h3v-6.8c0-5.4-5.7-5.2-7.1-2.6z" />

            </g>

        </svg>
    )
};