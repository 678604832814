import api from "../../veracityapi";
import { websiteData } from "../../Redux/actions";

export default function Data(params, dispatch) {

	const websiteGUID = localStorage.getItem('selected_site');
	const customerGUID = localStorage.getItem("selected_customer");
	const isAdmin = localStorage.getItem('admin') === 'true';
	const userGUID = localStorage.getItem("userGUID");
	let limit=7;
	let page=1;

	const getWebsiteDetails = new Promise((resolve, reject) => {
		api.GET(`website/${websiteGUID}`)
			.then(res => {
				resolve({ websiteDetails: res.item });
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getLicenceKeys = new Promise((resolve, reject) => {
		api.GET(`/license/website/${websiteGUID}`)
			.then(res => {
				resolve({ licenceKeys: res.items });
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getUnacceptedInvites= new Promise((resolve,reject)=>{
		api.GET(`/invites/unaccepted/${customerGUID}`)
		.then(res=>{
			resolve({invites:res});
		}).catch((error) => {
			api.handleError(error);
		});
	});

	const getCustomerPackages = new Promise((resolve, reject) => {
		api.GET(`/package/view_packages/${customerGUID}/`)
			.then(res => {
				resolve({ customerPackages: res.item });
			}).catch((error) => {
				api.handleError(error);
			});
	});

	const getCustomerInvoices = new Promise((resolve, reject) => {
		api.GET(`/customer/invoice/${customerGUID}/`)
			.then(res => {
				resolve({ customerInvoices: res.item });
			}).catch((error) => {
				api.handleError(error);
			});
	});
	
	const getEmailSettings = new Promise((resolve, reject) => {
		api.GET(`/user/${userGUID}/emailsettings/`)
			.then(res => {
				resolve({ emailSettings: res.item });
			}).catch((error) => {
				api.handleError(error);
			});
	});
		
	 const getPaymentMethods = new Promise((resolve, reject) => {
		api.GET(`/stripe/${customerGUID}/paymentMethods`)
			.then(res => {
				resolve({ paymentMethods: res.item });
			}).catch((error) => {
				if(error.response.status == "404") {
					resolve({ paymentMethods: [] });
				} else {
					api.handleError(error);
				};
			});
	});


	return new Promise((resolve, reject) => {
		Promise.all([getLicenceKeys, getWebsiteDetails, getCustomerPackages, getCustomerInvoices,getPaymentMethods, getEmailSettings,getUnacceptedInvites]).then(async (values) => {
			const temp = {
				timeRange: params.timeRange,
			};
			for (let obj of values) {
				Object.assign(temp, obj);
			};
			dispatch(websiteData(temp));
			resolve(true);
		});
	});
	
}