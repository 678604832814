import React from "react";
import Lottie from "lottie-react";
import animatedData from "./data-processing.json";
import "./DataProcessing.scss";

const DataProcessing = ({ heading, text }) => {

    return (
        <div className="dataprocessing-container">
            <div style={{ width: "150px", height: "150px", margin: "0 auto 50px auto" }}>
                <Lottie animationData={animatedData.animation} loop={true} />
            </div>
            <h1 style={{ marginBottom: 0, fontWeight: "bolder" }}>{heading}</h1>
            <p style={{ marginBottom: 0 }}>{text}</p>
            <p><b>Once this process has completed, you will be be notified that your data is ready to view.</b></p>
        </div>
    )
};


export default DataProcessing;