import React from "react";
import { Sidebar, sidebarClasses, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import { MenuData } from './data';
import { Link, useLocation } from 'react-router-dom';
import { SmallLogo, ProtectionIcon } from "./icons";
import StatusTabs from "./statusTabs/statusTabs";
import { useDispatch, useSelector } from "react-redux";
import "./Sidebar.scss";
import SideBarArrowIcon from '../../SVG/SideBarArrowIcon';
import { classicWebsiteGUIDs } from "components/AFP/Constants";

const SideBar = () => {
    const websiteDetails = useSelector(state => state?.websiteDetails?.data?.websiteDetails);
    const detectModes = useSelector(state => state?.detectModes);
    const isAdmin = localStorage.getItem("admin") === "true";
    const displayWTPSection = (!classicWebsiteGUIDs.includes(websiteDetails?.guid) || detectModes?.data?.wtp_status !== "off");
    const { pathname } = useLocation()
    const [collapsed, setCollapsed] = React.useState(false);
    const data = [];
    if (pathname === '/' || pathname === '/select' || pathname == '/loading') {
        return null;
    }
    // if ((!(isAFPEnabled)) && (!(isAffiliatesEnabled))) {
    //     data = MenuData[0].children
    // } else if (!(isAFPEnabled)) { //No AFP yes Affiliates
    //     delete MenuData[1]
    //     data = MenuData
    // } else if (!(isAffiliatesEnabled)) { //No Affiliates yes AFP
    //     delete MenuData[2]
    //     data = MenuData
    // } else {
    //     data = MenuData;
    // }

    if (displayWTPSection && websiteDetails?.last_traffic !== null) {
        data.push(MenuData[0]);
    };
    if (detectModes?.data?.afp_status !== "off" && websiteDetails?.last_traffic !== null) {
        data.push(MenuData[1]);
    };
    if (isAdmin && websiteDetails?.last_traffic !== null) {
        data.push(MenuData[3]);
    };
    if (websiteDetails?.last_traffic !== null && websiteDetails?.affiliates_enabled) {
        data.push(MenuData[2])
    };

    return (
        websiteDetails?.last_traffic !== null &&
        <Sidebar collapsed={collapsed} rootStyles={{
            [`.${sidebarClasses.container}`]: {
                backgroundColor: "rgba(33, 23, 40, 1)",
                position: "unset",
                padding: '20px',
                display: 'flex',
                flexDirection: 'column'
            },
            [`&.${sidebarClasses.collapsed}`]: {
                [`.${menuClasses.subMenuRoot} > .${menuClasses.button} > .${menuClasses.label}`]: {
                    display: 'none'
                },
                [`&.${sidebarClasses.collapsed}`]: {
                    [`.${menuClasses.subMenuContent}`]: {
                        backgroundColor: "rgba(33, 23, 40, 1)",
                        padding: "6px 2px 3px 2px"
                    }
                },
                [`.${sidebarClasses.container}`]: {

                    padding: '10px'
                },
                [`.${menuClasses.SubMenuExpandIcon}`]: {
                    right: '5px',
                    top: '45%'
                }
            },

            [`.${menuClasses.subMenuRoot} > .${menuClasses.button}`]: {
                paddingLeft: '4px',
                paddingRight: '6px',
            },
            [`.${menuClasses.subMenuContent}`]: {
                backgroundColor: 'transparent'
            }
        }} className="sidebar-main" >
            {/* Temporarily Hidden until seperate AFP protection status */}
            {/* <div className="loginlogo" style={{ width: "100%", borderBottom: !collapsed ? "1px solid #E9E0EF" : "" }}  >
                <ProtectionIconGetter toggle={!collapsed} />
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div onClick={() => setCollapsed(!collapsed)} className={!collapsed ? "closeArrow" : "openArrow"}>
                    <SideBarArrowIcon fill={"rgba(33, 23, 40, 1)"} />
                </div>
            </div>
            <Menu menuItemStyles={{
                root: {
                    [`&.${menuClasses.open}`]: {
                        backgroundColor: "#372e3d",
                        borderRadius: "8px",
                        marginBottom: "8px",
                        padding: "0px"
                    },

                },
                icon: {
                    width: '50px',
                },
                button: {
                    color: '#fff',
                    borderRadius: '8px',
                    margin: "0px 6px 3px 6px",
                    padding: '4px 4px',

                    backgroundColor: "#211728",
                    [`&:hover`]: {
                        backgroundColor: "#211728",
                        fontWeight: 'bolder'
                    },
                    [`&.${menuClasses.open}`]: {
                        background: "none",
                    },

                    [`&.${menuClasses.active}`]: {
                        backgroundColor: '#ffffff',
                        color: '#342343',
                        padding: '4px 4px',

                        [`.svg-shape`]: {
                            stroke: "black"
                        },
                        [`.svg-shape-other`]: {
                            stroke: "black"
                        }
                    },

                },
            }}>

                {data.map((item) => {
                    let active = pathname == item.path;
                    if (item.children) {
                        const open = item.children.filter(i => pathname.indexOf(i.path) > -1).length > 0;
                        return (
                            <SubMenu defaultOpen={open} icon={item.icon} title={item.title} label={item.title}>
                                {item.children.map((item) => {
                                    active = pathname.indexOf(item.path) > -1;
                                    return <MenuItem active={active} icon={item.icon} component={<Link to={item.path} />}>{item.title}</MenuItem>

                                })}
                            </SubMenu>)
                    } else {
                        return <MenuItem active={active} icon={item.icon} component={<Link to={item.path} />}>{item.title}</MenuItem>
                    }
                })
                }


            </Menu>
            <StatusTabs collapsed={collapsed} />
            <div className="bottom-logo" style={{ width: "100%", borderTop: !collapsed ? "1px solid #E9E0EF" : "" }}>
                {!collapsed ? <img width="150px" src={require("../../components/images/Veracity-compact-white.png")} alt="veracity-logo" /> : <SmallLogo className="side-bar-logo-small" />}
            </div>
        </Sidebar >);
}
export default SideBar;