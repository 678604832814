import api from "../../../../API/BeaconAPI";
import { CurrentDataAct } from "../../../../Redux/actions";
import { getStartTimeStampFromString, getEndTimeStampFromString } from "../../../../SharedComponents/DateManager";


// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ adType, timeRange = 'Prev 7 Days', campaignId = -1, linkId = -1 }, dispatch) {
	const websiteID = api.getWebsiteId();
	const start = getStartTimeStampFromString(timeRange);
	const end = getEndTimeStampFromString(timeRange);
	if (!adType) {
		return false;
	}
	const handleRequest = (url, resolve, reject) => {
		api.GET(url).then(res => {
			resolve(
				res.response
			);
		}).catch((error) => {
			console.log("Current Data Error: ", error);
			reject(error);
		});
	}
	const getClicks = new Promise((resolve, reject) => {
		let url = `3.0/graph/${websiteID}/pd/links/${start}:${end}/${adType}`
		if (campaignId > -1) {
			url = `3.0/graph/${websiteID}/pd/campaigns/${campaignId}/${start}:${end}/${adType}`;
		} else if (linkId > -1) {
			url = `3.0/graph/${websiteID}/pd/links/${linkId}/${start}:${end}/${adType}`
		}
		handleRequest(url, resolve, reject);
	});
	const getBaselineData = new Promise((resolve, reject) => {
		handleRequest(`3.0/website/${websiteID}/baseline/${adType}`, resolve, reject);
	});
	const getCurrentData = new Promise((resolve, reject) => {
		let url = `3.0/website/${websiteID}/pd/campaigns/${start}:${end}/${adType}`;
		if (campaignId > -1) {
			url = `3.0/website/${websiteID}/pd/campaigns/${campaignId}/links/${start}:${end}/${adType}`;
		} else if (linkId > -1) {
			// resolve(defa.filter(i=>i.link_id === parseInt(linkId)));
			// return;
			url = `3.0/link/${linkId}/${start}:${end}/website/${websiteID}/${adType}`;
		}
		handleRequest(url, resolve, reject);
	});


	const processDataCreateSummary = (data, baseline) => {
		const summary = {};
		const headers = data[0] && Object.keys(data[0]) || [];
		headers.map(i => {
			if (typeof data[0][i] == 'number') {
				summary[i] = data.reduce((a, o) => a + (o[i] ?? 0), 0);
			} else if (typeof data[0][i] == 'string') {
				summary[i] = ''
			}
		})
		const baselineEnggP = baseline?.clicks !== 0 ? parseFloat(baseline?.engaged / baseline?.clicks * 100 || 0).toFixed(1) : 0;
		const baselineHp = (() => {
			if (baseline?.clicks === 0) {
				if (baseline?.visits === 0) {
					return 0;
				} else {
					return parseFloat((baseline?.humans / baseline?.visits * 100).toFixed(1));
				}
			}
			if (baseline?.humans > 0) {
				return parseFloat((baseline?.humans / baseline?.clicks * 100).toFixed(1));
			} else {
				return parseFloat((baseline?.visits / baseline?.clicks * 100).toFixed(1));
			}
		})();
		console.log('baselineHp', baselineHp);

		const engagementPercentage = (summary?.engaged / summary?.clicks * 100 || 0).toFixed(1)
		summary['bot_percentage'] = summary?.clicks > 0 ? parseFloat((summary?.bots / summary?.clicks * 100).toFixed(1)) : parseFloat((summary?.bots / summary?.visits * 100).toFixed(1));
		summary['bot_percentage_classic'] = summary?.clicks > 0 ? parseFloat((summary?.bots_classic / summary?.clicks * 100).toFixed(1)) : 0;
		summary['human_percentage'] = summary?.clicks > 0 ? parseFloat((summary?.humans / summary?.clicks * 100).toFixed(1)) : parseFloat((summary?.humans / summary?.visits * 100).toFixed(1));
		summary['human_percentage_classic'] = summary?.clicks >= 0 ? parseFloat((summary?.visits / summary?.clicks * 100).toFixed(1)) : 0;
		summary['change_in_human_percentage'] = baselineHp && parseFloat((parseFloat(summary['human_percentage']) - baselineHp) / baselineHp * 100).toFixed(1);
		summary['change_in_human_percentage_classic'] = baselineHp && parseFloat((parseFloat(summary['human_percentage_classic']) - baselineHp) / baselineHp * 100).toFixed(1);
		summary['change_in_engagement_percentage'] = baselineEnggP && parseFloat((engagementPercentage - baselineEnggP) / baselineEnggP * 100).toFixed(1);
		summary['human_click_cost'] = summary?.visits >= 0 ? parseFloat((summary?.spend / summary?.humans).toFixed(2)) : 0;
		summary['human_click_cost_classic'] = summary?.visits >= 0 ? parseFloat((summary?.spend / summary?.visits).toFixed(2)) : 0;
		summary['invalid_clicks_percentage'] = summary?.clicks >= 0 ? parseFloat((summary?.invalid_clicks / summary?.clicks * 100).toFixed(1)) : 0;
		summary['engagement_percentage'] = engagementPercentage;
		return summary;
	}
	return new Promise((resolve, reject) => {
		Promise.all([getClicks, getCurrentData, getBaselineData]).then(([clicks, values, baseline]) => {
			let data = {};
			let summary = processDataCreateSummary(values, baseline);
			data[adType] = { values, summary, clicks, baseline };
			dispatch(CurrentDataAct({ data }));
			resolve(true);
		}).catch((error) => {
			api.handleError(error);
			reject(error);
		});
	});
};